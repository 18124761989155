<template>
  <div>
    <p>Instructor</p>

    <v-combobox
      ref="combo"
      v-model="selectedValue"
      :return-object="true"
      :items="values"
      item-avatar="avatar"
      item-text="name"
      item-value="uniqid"
      prepend-icon="fas fa-user-graduate"
      placeholder="Seleccione un profesor para ver sus estadísticas"
      @change="change"
      class="pt-0"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/emoji/sad-face.png" width="18"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>No se encontraron profesores con ese nombre</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:item="data">
        <v-avatar size="35" class="mr-2">
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{data.item.name}} {{data.item.lastname}}
      </template>

      <template v-slot:selection="data">
        <v-avatar size="25" class="mr-2">
          <v-img :src="data.item.avatar" />
        </v-avatar>
        <span class="item-selected">{{data.item.name}} {{data.item.lastname}}</span>
      </template>
    </v-combobox>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'TeacherCombo',
    data(){
      return{
        selectedValue: null
      }
    },
    computed:{
      ...mapGetters({
        findTeacher: 'services/api/course/coordinator/teachers/findTeacher'
      })
    },
    methods:{
      getValue: function() {
        let me = this;
        return me.selectedValue;
      },
      change: function(){
        let me = this;

        if(null === me.selectedValue || typeof me.selectedValue !== "object"){
          me.$refs['combo'].setValue(null);
          return me.$emit('reset');
        }

        return me.$emit('change', me.selectedValue);
      },
      reset: function(){
        let me = this;
        me.selectedValue = null;
      }
    },
    mounted() {
      let me = this;

      if(null === me.preSelectedTeacher){
        return;
      }

      me.selectedValue = me.findTeacher(me.preSelectedTeacher);
      me.change();
    },
    props:{
      values:{
        type: Array,
        required: true
      },
      preSelectedTeacher:{
        type: String,
        required: false,
        default: null
      }
    }
  }
</script>

<style scoped>

</style>
