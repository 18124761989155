<template>
  <v-card class="elevation-0 pa-3">

    <v-card-text v-if="0 === stats.questions.length" class="pa-3" style="height:70vh;">
      <div class="pa-5 text-center">
        <h2>Nadie ha completado la encuesta aún</h2>
      </div>
    </v-card-text>

    <v-card-text v-if="stats.questions.length > 0">
      <v-row v-for="(question, i) in stats.questions" :key="i">
        <v-col cols="10">
          {{question.text}}
        </v-col>

        <v-col cols="2">
          <span :style="scoreClass(scoreThermometer, question.value)">{{question.value}}</span>
        </v-col>
      </v-row>

      <v-divider />

      <v-row>
        <v-col cols="10">
          Cantidad de alumnos que completaron la encuesta
        </v-col>

        <v-col cols="2">
          {{stats.surveyed}} / {{stats.totalStudents}}
        </v-col>

        <v-col cols="10">
          Promedio
        </v-col>

        <v-col cols="2">
          <span :style="scoreClass(scoreThermometer, stats.myAverage)">{{stats.myAverage}}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import ScoreColor from '@/components/ui/score/mixins/ScoreColor'

  export default {
    name: 'GeneralStats',
    mixins:[
      ScoreColor
    ],
    props:{
      stats:{
        type: Object,
        required: true
      },
      scoreThermometer:{
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
