<template>
  <v-row class="pa-0" align="start" justify="space-between">

    <v-col cols="12" md="12" class="pa-0">
      <v-card class="elevation-0 card-container">
        <v-tabs
          v-model="tab"
          background-color="primary accent-4"
          centered
          grow
          dark
          icons-and-text
          :show-arrows="showArrows"
          class="pa-0"
        >
          <v-tabs-slider class="pa-0"></v-tabs-slider>

          <v-tab href="#tab-1">
            Detalles por alumno
            <v-icon>fas fa-poll-h</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            Estadísticas Generales
            <v-icon>fas fa-chart-line</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            value="tab-1"
          >
            <StudentStats
              :stats="detailedStats"
              :isBootcamp="isBootcamp"
              :minimum-score="generalStats.generalAverage"
              :scoreThermometer="scoreThermometer"
              @saveTasks="saveTasks"
            />
          </v-tab-item>
        </v-tabs-items>

        <v-tabs-items v-model="tab">
          <v-tab-item
            value="tab-2"
          >
            <GeneralStats
              :stats="generalStats"
              :scoreThermometer="scoreThermometer"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import Progress from '@/components/progress'
  import GeneralStats from '../detail/GeneralStats'
  import StudentStats from '../detail/StudentStats'

  export default {
    name: 'StatsList',
    components:{
      GeneralStats,
      StudentStats,
      Progress
    },
    data(){
      return{
        tab: null,
      }
    },
    methods:{
      saveTasks: function(tasks){
        let me = this;
        me.$emit('saveTasks', tasks);
      }
    },
    props:{
      generalStats:{
        type: Object,
        required: true
      },
      detailedStats:{
        type: Array,
        required: true
      },
      scoreThermometer:{
        type: Array,
        required: true
      },
      showArrows:{
        type: Boolean,
        default: false
      },
      isBootcamp:{
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style scoped>
  .card-container{
    border:1px solid #f1f1f1;
  }
</style>
