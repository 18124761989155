<template>
  <v-breadcrumbs :items="routes" class="breadcrumbs">
    <template v-slot:divider>
      <v-icon>chevron_right</v-icon>
    </template>
    <template v-slot:item="props">
      <div v-if="null !== props.item.route">
        <router-link v-if="props.item.route !== null" :to="{ name: props.item.route, params: props.item.params}">
          {{ props.item.name }}
        </router-link>
      </div>

      <div class="no-link" v-if="null === props.item.route">
        {{ props.item.name }}
      </div>
    </template>
  </v-breadcrumbs>
</template>

<script>

  export default{
    name: 'Breadcrumb',
    props:{
      routes:{
        type: Array,
        default:() => {
          return [];
        }
      }
    }
  }
</script>

<style scoped>
  .breadcrumbs{
    padding:0 0 0 10px;
  }

  @media (min-width: 961px) {
    .breadcrumbs{
      height:40px;
    }
  }

  @media (max-width: 960px){
    .breadcrumbs{
      padding:5px;
    }
  }
  .breadcrumbs{
    border-bottom:1px solid #ccc;
  }

</style>
