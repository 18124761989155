<template>
  <v-container fluid class="pa-0 ma-0">
    <CenteredContent v-if="true === isFetchingCalendars">
      <template v-slot:content>
        <v-progress-circular
          :size="80"
          indeterminate
          color="primary"
        />
      </template>
    </CenteredContent>

    <v-row v-if="null !== course">
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="auto">
            <v-avatar>
              <CourseIcon :icon="course.icon"/>
            </v-avatar>
          </v-col>

          <v-col>
            <h2>{{course.name}}</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="true === isCoordinator && false === isFetchingTeachers" class="card">
      <v-col cols="12">
        Opciones de coordinación
      </v-col>

      <v-col cols="12" md="6">
        <TeacherCombo
          ref="teacherCombo"
          :values="teachers"
          :pre-selected-teacher="preSelectedTeacher"
          @change="changeTeacher"
        />
      </v-col>

      <v-col cols="12" md="6">
        <YearCombo
          ref="YearCombo"
          :server-date="serverDate"
          @change="updateTeacherCalendars"
        />
      </v-col>
    </v-row>

    <v-row v-if="false === loadingCalendars && getCalendars.length === 0" align="center" justify="start">
      <v-col cols="12">
        <v-alert
          outlined
          type="warning"
          prominent
          border="left"
          class="mt-5"
        >
          No hay fechas asignadas
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="getCalendars.length > 0">
      <v-col cols="12">
        <Dates
          ref="calendarsCombo"
          :loading="true === loadingCalendars"
          :values="getCalendars"
          :translations="translations"
          @change="updateStats"
        />
      </v-col>
    </v-row>

    <v-row v-if="false === loadingCalendars && getCalendars.length > 0">
      <v-col v-if="true === isFetchingStatsGeneral" class="text-center">
        <v-progress-circular
          :size="80"
          indeterminate
          color="primary"
        />
      </v-col>

      <v-col cols="12" class="border-slight" v-if="false === isFetchingStatsGeneral">
        <v-card flat class="elevation-0">
          <v-container fluid class="pa-0">
            <v-row justify="space-between">
              <v-col cols="12" sm="3" class="text-center">
                <vm-progress
                  :percentage="generalStats.completed"
                  :strokeWidth="8"
                  type="circle"
                  :width="150"
                />
                <p>Total de encuestados</p>
              </v-col>

              <v-col cols="12" sm="3" class="text-center">
                <vm-progress
                  :percentage="generalStats.myAverage > 0 ? generalStats.myAverage * 20 : 0"
                  :strokeWidth="8"
                  :strokeColor="generalStats.myAverage >= generalStats.idealAverage ? 'green' : 'red'"
                  type="circle"
                  :width="150"
                >
                  <template>{{generalStats.myAverage}}</template>
                </vm-progress>
                <p>Mi Puntaje</p>
              </v-col>

              <v-col cols="12" sm="3" class="text-center">
                <vm-progress
                  type="circle"
                  :percentage="generalStats.generalAverage > 0 ? generalStats.generalAverage * 20 : 0"
                  :width="150"
                  :strokeWidth="8"
                >
                  <template>{{generalStats.generalAverage}}</template>
                </vm-progress>

                <p>Media Anual de la institución</p>
                <p v-if="generalStats.generalAverage === 0" class="caption">(No hay suficientes datos)</p>
                <p v-if="generalStats.generalAverage > 0" class="caption">(Calculada sobre {{generalStats.completedYTD}} cursos calificados)</p>
              </v-col>

              <v-col cols="12" sm="3" class="text-center">
                <vm-progress
                  type="circle"
                  :percentage="generalStats.idealAverage * 20"
                  :width="150"
                  :strokeWidth="8"
                >
                  <template>{{generalStats.idealAverage}}</template>
                </vm-progress>

                <p>Promedio ideal</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="false === isFetchingStatsGeneral && getCalendars.length > 0">
      <v-col cols="12">
        <PageLoader
          :display="true === isFetchingStatsDetail"
          :size="80"
          height="50%"
          width="50%"
        />

        <StatsTabs
          v-if="false === isFetchingStatsDetail"
          :generalStats="generalStats"
          :detailedStats="detailedStats"
          :courseName="course.name"
          :isBootcamp="course.isBootcamp"
          :scoreThermometer="scoreThermometer"
          :showArrows="isMobile"
          @saveTasks="saveTasks"
        />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

  import Progress           from '@/components/progress'
  import Dates              from './_components/DateCombo'
  import StatsTabs          from './_components/stats'
  import PageLoader         from '@/components/ui/loader/PageLoader'
  import CenteredContent    from '@/components/ui/fullscreen/CenteredContent'
  import { mapGetters } from 'vuex'
  import TeacherCombo from './_components/TeacherCombo'
  import YearCombo from './_components/YearCombo'
  import CourseIcon from '@/components/ui/course/Icon'

  export default {
    name: 'index',
    components:{
      YearCombo,
      TeacherCombo,
      Dates,
      PageLoader,
      StatsTabs,
      CenteredContent,
      Progress,
      CourseIcon
    },
    data(){
      return{
        scoreThermometer: [
          {name: 'ok', color:'#228C22', min: 4.50, max:5.00},
          {name: 'warning', color:'#e79100', min: 4.00, max: 4.49},
          {name: 'error', color:'#B00020', min: 0.00, max: 3.99}
        ],
        preSelectedTeacher: null,
        selectedTeacher: null,
        isCoordinator: false,
      }
    },
    computed:{
      ...mapGetters({
        translations: 'services/api/translation/getTranslations',
        isFetchingCalendars: 'services/api/course/calendar/calendar/fetching',
        isFetchingTeachers: 'services/api/course/coordinator/teachers/fetching',
        isFetchingTeacherCalendars: 'services/api/course/coordinator/calendars/fetching',
        isFetchingStatsGeneral: 'services/api/course/calendar/stats/general/fetching',
        isFetchingStatsDetail: 'services/api/course/calendar/stats/detail/fetching',
        course: 'services/api/course/calendar/calendar/course',
        calendars: 'services/api/course/calendar/calendar/list',
        teachers: 'services/api/course/coordinator/teachers/list',
        yearCalendars: 'services/api/course/coordinator/calendars/list',
        generalStats: 'services/api/course/calendar/stats/general/values',
        detailedStats: 'services/api/course/calendar/stats/detail/values',
        serverDate: 'services/api/server/date/date',
        currentUser: 'services/api/auth/uniqid',
        isMobile: 'services/browser/window/mobile',
      }),
      getCalendars: function(){
        let me = this;

        return true === me.isCoordinator ? me.yearCalendars : me.calendars;
      },
      loadingCalendars: function(){
        let me = this;
        return true === me.isCoordinator ? me.isFetchingTeacherCalendars : me.isFetchingCalendars;
      }
    },
    methods:{
      getYearComponent: function(){
        let me = this;
        return me.$refs['YearCombo'];
      },
      updateTeacherCalendars: function(year){
        let me = this;

        me.$store.dispatch('services/api/course/coordinator/calendars/fetch',{
          course: me.courseId,
          user: me.selectedTeacher.uniqid,
          year: year
        })
        .then(response => {
            if(response.data.length > 0){
              me.updateStats(response.data[0].id)
            }
        })
      },
      changeTeacher: function(teacher){
        let me = this,
          yearComponent = me.getYearComponent();

        me.selectedTeacher = teacher;

        me.updateTeacherCalendars(yearComponent.getValue());
      },
      updateStats: function(calendar){
        let me = this,
          route = Object.assign({}, me.$route);

        route.params = {course:me.courseId, calendar: calendar};

        me.$router.replace(route).catch(()=>{});

        /**
         * Fetch course general statistics
         */
        me.fetchStatsGeneral(calendar);

        /**
         * Fetch individual (calendar specific) statistics
         */
        me.fetchStatsDetail(calendar);
      },
      fetchStatsGeneral: function(calendar){
        let me = this;

        me.$store.dispatch('services/api/course/calendar/stats/general/fetch',{
          course: me.courseId,
          calendar: calendar,
          user: null !== me.selectedTeacher ? me.selectedTeacher.uniqid : null
        });
      },
      fetchStatsDetail: function(calendar){
        let me = this;

        me.$store.dispatch('services/api/course/calendar/stats/detail/fetch',{
          course: me.courseId,
          calendar: calendar,
          user: null !== me.selectedTeacher ? me.selectedTeacher.uniqid : null
        });
      },
      saveTasks: function(task){
        let me = this;
        me.$store.dispatch('services/api/bootcamp/update',{
          user: task.user,
          course: me.courseId,
          calendar: me.calendarId,
          tasks: task.tasks
        })
      }
    },
    mounted(){
      let me = this;

      me.$store.dispatch('services/api/course/calendar/calendar/fetch', {
        course: me.courseId,
        calendar: me.calendarId
      })
        .then(response => {
          if(typeof response === 'undefined') {
            return;
          }

          if(false === response.data.isCoordinator){
            me.updateStats(response.data.calendars[0].id);
          }

          if(true === response.data.isCoordinator){
            me.preSelectedTeacher = me.currentUser;
            me.isCoordinator = true;

            me.$store.dispatch('services/api/course/coordinator/teachers/fetch', {
              course: me.courseId
            });
          }
        })
    },
    props:{
      courseId:{
        type: [ String, Number ],
        required: true
      },
      calendarId:{
        type: [ String, Number ],
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
