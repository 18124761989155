<template>
  <div class="date-combo">

    <p>Fechas asignadas</p>

    <v-select
      :items="dates"
      @change="change"
      :value="current"
      item-value="id"
      item-text="text"
      class="input-calendar"
      prepend-inner-icon="fa fa-calendar"
      :loading="loading"
    >
      <template v-slot:selection="data">
        Calendario: {{ data.item.idCourseCalendar }} - {{ data.item.text }}
      </template>
      <template v-slot:item="data">
        Calendario: {{ data.item.idCourseCalendar }} - {{ data.item.text }}
      </template>
    </v-select>
  </div>
</template>

<script>
  import DateMixin from '@/mixins/DateText'

  export default {
    name: 'DateCombo',
    mixins:[
      DateMixin
    ],
    computed:{
      current: function() {
        let me = this;

        for(let i = 0; i < me.values.length; i++){
          if(true === me.values[i].current){
            return me.values[i].id;
          }
        }
      },
      dates: function(){
        let me = this,
          values = me.values,
          trans = me.translations.calendar;

        values = values.filter( item => {
          return item.start==='0000-00-00'||item.end==='0000-00-00' ? false : item;
        });

        return values.map(item => {
          return {
            id: item.id,
            idCourseCalendar: item.idCourseCalendar,
            text: [
              me.parseDateTextualMixin(item.start, false),
              '/',
              me.parseDateTextualMixin(item.end, false),
              '「',
              'D' === item.mode ? trans.mode.remote : trans.mode.faceToFace,
              '」'
            ].join(' ')
              .replace(
                /^(.)|\s+(.)/g, ($1) => {
                  return $1.toUpperCase()
                })
          }
        });
      }
    },
    methods:{
      change(calendarId){
        let me = this;

        if(me.dates.length === 1){
          return;
        }

        me.$emit('change', calendarId);
      }
    },
    props:{
      values: {
        type: Array,
      },
      translations:{
        type: Object
      },
      loading:{
        type: Boolean
      }
    }
  }
</script>

<style scoped>
  .loader{
    min-height:20px;
  }

  .loader p{
    padding:4px;
    margin-top:20px;
  }
  .input-calendar{
    padding-top: 0;
  }
</style>
