<template>
  <div>
    <p>Calendarios desde el año</p>

    <v-select
      v-model="selectedYear"
      :items="years"
      class="pt-0"
      @change="change"
    >
    </v-select>

  </div>
</template>

<script>
  export default {
    name: 'YearCombo',
    data(){
      return {
        selectedYear: null,
      }
    },
    computed:{
      years:function(){
        let me = this,
          date = new Date(me.serverDate),
          maxYear = date.getFullYear(),
          minYear = maxYear - 2,
          result = [];

        for(let i = maxYear; i >= minYear; i--){
          result.push(i);
        }

        return result;
      }
    },
    mounted(){
      let me = this;

      me.selectedYear = new Date(me.serverDate).getFullYear();
      me.change();
    },
    methods:{
      getValue: function(){
        let me = this;
        return me.selectedYear;
      },
      change: function(){
        let me = this;

        return me.$emit('change', me.selectedYear);
      },
      reset: function(){
        let me = this;
        me.selectedYear = new Date(me.serverDate).getFullYear();
        me.change();
      }
    },
    props:{
      serverDate:{
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
