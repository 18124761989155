<template>
  <v-content v-if="authenticated">
    <Toolbar/>
    <BreadCrumb :routes="breadCrumb" class="pl-10" />

    <v-row justify="center" align="center" no-gutters>
      <v-col cols="11" class="pt-0">
        <CourseCalendar
          :courseId="course"
          :calendarId="calendar"
        />
      </v-col>
    </v-row>
  </v-content>
</template>

<script>

  import TranslationsMixin  from '@/mixins/Translation'
  import Toolbar            from '@/components/toolbar/index'
  import CourseCalendar     from '@/modules/course/calendar'
  import BreadCrumb         from '@/components/ui/breadcrumb'
  import { mapGetters }     from 'vuex'

  export default{
    name: 'CourseCalendarView',
    mixins:[
      TranslationsMixin
    ],
    components:{
      Toolbar,
      CourseCalendar,
      BreadCrumb
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
      })
    },
    data(){
      return {
        breadCrumb:[
          {
            name: 'Dashboard',
            route: 'Dashboard'
          }
        ]
      }
    },
    props:{
      course: {
        type: [String, Number]
      },
      calendar:{
        type: [String, Number],
        required: false
      }
    }
  }
</script>

<style scoped>
  .scroll{
    height: 90vh;

  }
</style>
