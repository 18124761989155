<template>
  <v-container
    class="centered-content"
    :style="'background-color: ' + background"
    fluid
  >
    <v-layout column class="align-center justify-center fill-height">
      <slot name="content">
        <v-flex xs3>
          Add content here
        </v-flex>
      </slot>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name: "CenteredContent",
    props:{
      background:{
        type: String,
        default: 'white'
      }
    }
  }
</script>

<style scoped>
  .centered-content{
    height:100vh;
    width:100vw;
  }

</style>
