<template>
  <v-container fluid>
    <v-card v-if="typeof stats.success !== 'undefined'" class="elevation-0">
      <v-card-text class="pa-3" style="height:70vh;">
        <div class="pa-5 text-center">
          <h2>No se han encontrado estudiantes en este calendario</h2>
        </div>
      </v-card-text>
    </v-card>

    <v-list v-if="stats.length > 0">
      <v-list-item
        v-for="(stat, i) in stats"
        :key="i"
        nav
        class="list-hover"
      >
        <v-list-item-avatar>
          <v-img :src="stat.student.avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{getStudentName(stat)}}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="isBootcamp">
          <v-list-item-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    fab
                    small
                    class="elevation-0"
                    outlined
                    color="primary"
                    @click.prevent="showTaskModal(stat)"
                  >
                    <v-icon color="primary">
                      fas fa-tasks
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Tareas</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item-action>

        <v-list-item-action>
          <v-list-item-icon>
            <v-btn
              fab
              small
              class="elevation-0"
              :disabled="'' === getComment(stat)"
              @click.prevent="showComment(getComment(stat))"
              :outlined="'' === getComment(stat)"
            >
              <v-icon :color="getColor(stat)">
                far fa-comment-alt
              </v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item-action>

        <v-list-item-action>
          <v-list-item-icon>
            <!-- no survey, disabled button -->
            <v-btn
              v-if="0 === stat.survey.length"
              readonly
              :disabled="true"
              fab
              class="elevation-0"
              small
              outlined
              :ripple="false"
            >
              {{stat.score}}
            </v-btn>

            <!-- Survey completed and has a good score -->
            <v-btn
              v-if="stat.survey.length > 0 && stat.score >= minimumScore"
              style="cursor:default;"
              readonly
              fab
              color="success"
              class="elevation-0"
              small
              :ripple="false"
            >
              {{stat.score}}
            </v-btn>

            <!-- Survey completed and has a bad score -->
            <v-tooltip v-if="stat.survey.length >0 && stat.score < minimumScore" bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    readonly
                    fab
                    color="error"
                    :disabled="0 === stat.survey.length"
                    class="elevation-0"
                    small
                    :ripple="false"
                  >
                    {{stat.score}}
                  </v-btn>
                </div>
              </template>
              <span>La calificación está por debajo de la media del instituto</span>
            </v-tooltip>

          </v-list-item-icon>
        </v-list-item-action>

        <v-list-item-action>
          <!-- Student has not answered survey yet -->
          <v-list-item-icon>
            <v-tooltip v-if="stat.survey.length === 0" bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    text
                    fab
                    small
                    class="elevation-0"
                    disabled
                  >
                    <v-icon>
                      far fa-eye
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>No hay datos</span>
            </v-tooltip>

            <!-- Student has completed survey -->
            <v-tooltip v-if="stat.survey.length > 0 && '' !== getComment(stat)" bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    text
                    fab
                    small
                    class="elevation-0"
                    @click.prevent="showDetailsModal(stat)"
                  >
                    <v-icon :color="getColor(stat)">
                      far fa-eye
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{getComment(stat).value.substring(0, 80) + '...'}}</span>
            </v-tooltip>

            <v-btn
              v-if="stat.survey.length > 0 && '' === getComment(stat)"
              text
              fab
              small
              class="elevation-0"
              @click.prevent="showDetailsModal(stat)"
            >
              <v-icon :color="getColor(stat)">
                far fa-eye
              </v-icon>
            </v-btn>

          </v-list-item-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-dialog
      v-if="userTasks !== null"
      v-model="taskModal"
      width="400"
      scrollable
    >
      <v-card color="grey lighten-5" class="pa-0" height="600">
        <v-card-title>
          Tareas
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-3">
          <v-row
            v-for="(task, i) in userTasks"
            :key="i"
            align="center"
          >
            <v-col cols="3" class="ml-3">
              Entrega {{i}}
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="task.completed" />
            </v-col>

            <v-col
              v-if="task.finished"
              cols="4"
            >
              {{parseDateDMYFormatMixin(task.finished.date, false)}}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            color="error"
            width="100"
            @click.prevent="taskModal = false"
            :disabled="isUpdating"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="primary"
            width="100"
            @click.prevent="saveTasks"
            :disabled="isUpdating"
          >
            <span v-if="!isUpdating">Guardar</span>

            <v-progress-circular
              v-if="isUpdating"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="comment !== null"
      v-model="commentModal"
      width="500px"
    >
      <v-toolbar color="primary" class="elevation-0">
        <v-toolbar-title class="white--text">{{comment.student.name}} {{comment.student.lastname}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text fab small @click="commentModal = false" dark><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card color="grey lighten-5" class="pa-5">
        <v-card-text class="pa-3">
          {{comment.value}}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selected !== null"
      v-model="show"
      width="500px"
    >
      <v-toolbar color="primary" class="elevation-0">
        <v-toolbar-title class="white--text">{{selected.student.name}} {{selected.student.lastname}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text fab small @click="show = false" dark><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-row
            v-for="(question, i) in selected.survey"
            :key="i"
            align="start"
            justify="start"
          >
            <v-col cols="10">
              {{question.text}}
            </v-col>

            <v-col cols="2" v-if="'numeric' === question.type">
              <span :style="scoreClass(scoreThermometer, question.value)">{{question.value}}</span>
            </v-col>

            <v-col cols="12" v-if="'text' === question.type">
              <v-card
                v-if="question.value && question.value.length > 0"
                color="secondary lighten-1"
                class="pa-5 elevation-0"
              >
                <v-card-text class="white--text">{{question.value}}</v-card-text>
              </v-card>

              <v-card
                v-if="!question.value"
                color="grey lighten-3"
                class="pa-5 white--text elevation-0"
              >
                <v-card-text>No hay comentarios</v-card-text>
              </v-card>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      :value="successUpdate"
      color="success"
    >
      Las tareas del alumno se actualizaron correctamente
    </v-snackbar>
  </v-container>
</template>

<script>
  import ScoreColor from '@/components/ui/score/mixins/ScoreColor'
  import DateMixin from '@/mixins/DateText'
  import { mapGetters } from 'vuex'

  export default {
    name: 'StudentStats',
    mixins:[
      ScoreColor,
      DateMixin
    ],
    data(){
      return{
        show: false,
        selected: null,
        commentModal: false,
        comment: null,
        taskModal: false,
        userTasks: null,
        selectedUserTask: null,
        showSnackbar: false
      }
    },
    computed:{
      ...mapGetters({
        isUpdating: 'services/api/bootcamp/fetching',
        successUpdate: 'services/api/bootcamp/success'
      })
    },
    methods:{
      getStudentName: function(stat){
        return [stat.student.name, stat.student.lastname].join(' ');
      },
      showDetailsModal: function(details){
        let me = this;

        me.selected = details;
        me.show = true;
      },
      showTaskModal: function(stat){
        let me = this;

        me.userTasks = stat.tasks;
        me.selectedUserTask = stat.student.uniqid;
        me.taskModal = true;
      },
      getColor: function(stat){
        let me = this;

        if(!me.hasSurvey(stat)) return 'grey';

        return stat.score >= me.minimumScore ? 'success' : 'error';
      },
      hasSurvey: function(stat){
        return stat.survey.length > 0;
      },
      showComment: function(comment){
        let me = this;
        me.commentModal = true;
        me.comment=comment;
      },
      getComment: stat => {
        let comment = stat.survey.filter(item => {
          return 'text' === item.type && null !== item.value && item.value.length > 0 ? item : null;
        });

        if(0 === comment.length){
          return '';
        }

        return {
          student: stat.student,
          value: comment[0].value,
          score: stat.score
        };
      },
      saveTasks: function(){
        let me = this;

        me.$emit('saveTasks', {
          user: me.selectedUserTask,
          tasks: JSON.stringify(me.userTasks)
        });
      }
    },
    props:{
      stats:{
        type: Array,
        required: true
      },
      minimumScore: {
        type: Number,
        required: true
      },
      scoreThermometer:{
        type: Array,
        required: true
      },
      isBootcamp:{
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style scoped>
  .list-hover:hover{
    background-color:#f0f0f0;
  }
</style>
